.zoom-toolbar.vertical {
  background: #fff;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1);
  width: 32px;
  border-radius: 4px;
  z-index: 10;
}
.zoom-toolbar.vertical .ant-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  border: none;
  margin: 10px auto;
  display: block;
}
.zoom-toolbar.vertical .ant-btn[disabled] {
  background-color: #fff;
}
.zoom-toolbar.horizontal {
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #fff;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}
.zoom-toolbar.horizontal .ant-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  border: none;
  margin-left: 10px;
  display: inline-block;
}
.zoom-toolbar.horizontal .ant-btn[disabled] {
  background-color: #fff;
}

.graphin-context-menu {
  display: block;
  width: 150px;
  border: none;
  background-color: #fff;
  padding: 4px 0;
}
.graphin-context-menu .ant-menu-root,
.graphin-context-menu .ant-menu-sub {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.graphin-context-menu .ant-menu-item {
  height: 32px;
  line-height: 32px;
  margin: 0;
}
.graphin-context-menu .ant-menu-item:hover {
  color: unset;
  background-color: #e6f7ff;
}
.graphin-context-menu .ant-menu-item-selected {
  color: unset;
  background-color: unset;
}
.ant-menu-item.dividing-line {
  height: 1px;
  margin: 9px 0;
  border-top: 1px solid #e8e8e8;
  cursor: default;
}
